.projectCardContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15vh 0 5vh 0;
    width: 100vw;
}

.projectCard {
    background-color: #D3D3D3; /* Background color of the card */
    color: #0B090A;
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px #660708; /* Subtle shadow */
    padding: 5%; /* Spacing inside the card */
    width: 50%; /* Make card responsive */
  }
  
  .projectImage {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  