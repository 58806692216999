/* Projects.css */
  
  .card-link {
    text-decoration: none;
    color: inherit; /* Keeps text color from card */
  }
  
  .image-text-card-wrapper {
    border: 1px solid #D3D3D3;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease;
    width: 60vw; /* Adjust width of cards */
    z-index: 1; /* Set z-index for individual cards */
    box-shadow: 0 4px 8px #660708; /* Subtle shadow */
    background-color: #D3D3D3;
  }
  
  .image-text-card-wrapper:hover {
    transform: scale(1.05);
  }
  
  .card-image {
    width: 100%;
    height: auto;
  }
  
  .card-content {
    padding: 16px;
    color: #0B090A;
  }