/* src/pages/Contact.css */
.contactCardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10vh 0 10vh 0;
  width: 100vw;
  }
  
  .contactCard {
    background-color: #D3D3D3; /* Background color of the card */
    color: #0B090A;
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px #660708; /* Subtle shadow */
    padding: 5%; /* Spacing inside the card */
    width: 40%; /* Make card responsive */
  }
  
  .contactCard form {
    display: flex;
    flex-direction: column;
  }
  
  .contactCard label {
    margin-bottom: 10px;
  }
  
  .contactCard input, .contactCard textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .contactCard button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .contactCard button:hover {
    background-color: #0056b3;
  }
  
  .status-message {
    margin-top: 10px;
    font-weight: bold;
  }
  
  .status-message.success {
    color: green;
  }
  
  .status-message.error {
    color: red;
  }
  
  .server-status {
    color: red;
    font-weight: bold;
    margin-top: 10px;
  }
  