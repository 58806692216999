.pageCardContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5% 0 5% 0;
    width: 100vw;
}
  
.pageCard {
    background-color: #D3D3D3; /* Background color of the card */
    color: #0B090A;
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px #660708; /* Subtle shadow */
    padding: 5%; /* Spacing inside the card */
    width: 50%; /* Make card responsive */
}
  