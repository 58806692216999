.nav {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
    padding: 10px;
  }

 .nav .link {
    color: #D3D3D3;
    text-decoration: none;
  }